/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const arrowPrev = '<button type="button" class="slick-prev"><i class="icon icon-arrow_left"></button>';
	const arrowNext = '<button type="button" class="slick-next"><i class="icon icon-arrow_right"></button>';

	// メインヴィジュアル
	const mainSlider = () => {
		$('.idx-mainVis').slick({
			infinite: true,
			speed: 3000,
			fade: true,
			swipe: false,
			autoplay: true,
			autoplaySpeed: 8000,
			slidesToShow: 1,
			arrows: false,
		});
		$('.idx-mainVis-sp').slick({
			infinite: true,
			speed: 3000,
			fade: true,
			swipe: false,
			autoplay: true,
			autoplaySpeed: 8000,
			slidesToShow: 1,
			arrows: false,
		});
	}
	// ティッカー
	const tickerSlider = () => {
		$('.idx-main-ticker-ul').slick({
			infinite: true,
			speed: 2000,
			autoplay: true,
			autoplaySpeed: 4000,
			slidesToShow: 1,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
		});
	}

	// メインビジュアル（キャッチコピー・背景）
	const mainCatch = () => {
		$('.idx-mainVis-catch-img01').addClass(a);
		$('.idx-mainVis-catch-img02').addClass(a);
		setTimeout(function(){
			$('.idx-mainVis-catch-img03').addClass(a);
		},2800);

		$('.idx-mainVis').on('beforeChange',function(event, slick, currentSlide, nextSlide){
			if(nextSlide > 0 ) {
				$('.idx-mainVis').slick('slickSetOption', 'autoplaySpeed', 5000, true)
			}
		})
	}


	// ローディングアニメーション
	const loadingBlock = $('.idx-loading');
	const percent = $('.idx-loading-count .num');
	const gauge = $('.idx-loading-gauge');
	let images = $('img');
	let count = 0;
	let current;

	// body縦スクロール非表示
	$('body').addClass(f);

	for(let i = 0; i < images.length; i++) {
		let img = new Image(); // 画像の作成
		// 画像読み込み完了したとき
		img.onload = function() {
			count += 1;
		}
		// 画像読み込み失敗したとき
		img.onerror = function() {
			count += 1;
		}
		img.src = images[i].src; // 画像にsrcを指定して読み込み開始
	};

	let nowLoading = setInterval(function() {
		// 現在の読み込み具合のパーセントを取得
		current = Math.floor(count / images.length * 100);
		// パーセント表示の書き換え
		percent.text(current);
		// ゲージの変更
		gauge.css('height', `${current}%`);
		// 全て読み込んだ時
		if(count == images.length) {
			setTimeout(() => {
				$('.idx-loading-inner').addClass(a);
				setTimeout(() => {
					loadingBlock.addClass('is-hidden');
					$('.idx-conts').addClass(a);
					$('body').removeClass(f);
					mainSlider();
					tickerSlider();
					setTimeout(() => {
						mainCatch();
					}, 800);
				}, 1000);
			}, 800);
			clearInterval(nowLoading);
		}
	}, 100);

})(window.jQuery, window.FUNCTIONS);
